import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutPage from "./AboutPage/AboutPage"

import "assets/scss/material-kit-react.scss?v=1.8.0"

const About = () => {
  return (
    <Layout>
      <SEO
        title={"About Us"}
        description="Find all you need to know about My Specials and how we can promote your products, and help people save money."
      />
      <AboutPage />
    </Layout>
  )
}

export default About
